<template>
    <b-card>
        <h4 slot="header" class="mb-0">
            {{ featureName }} <span class="badge bg-primary">Disponibile a breve</span> 
        </h4>
        <slot></slot>
    </b-card>
</template>

<script>
    export default {
        name: 'CoomingSoonFeature',
        props: {
            featureName: {
                type: String,
                default: ''
            },
        },
    };
</script>
