<template>
    <b-card>
        <h4 slot="header" class="mb-0">Informazioni aziendali</h4>
        <!-- <b-card-title>
            <h5 class="card-title">Principali</h5>
        </b-card-title> -->
        <b-card-text>
            <p class="my-1">
                <b>Ragione sociale: </b>
                    {{ store.company_name}}
            </p>
            <p class="my-1">
                <b>P. IVA: </b>
                    {{ store.company_id}}
            </p>
            <p class="my-1">
                <b>Regione: </b>
                    {{ store.compnay_region}} &nbsp;
                <b>Provincia: </b>
                    {{ store.company_province}}
                <b>Città: </b>
                    {{ store.company_city}}
            </p>
            <p class="my-1">
                <b>Indirizzo:</b>
                {{ store.company_address}}
            </p>
            <p class="my-1">
                
            </p>
        </b-card-text>
        <b-card-title>
            <h5 class="card-title">Account e licenza</h5>
        </b-card-title>
        <b-card-text>
            <div>
                <p class="my-1">
                    <b>Data creazione account: </b>
                        {{ store.created_at}}
                </p>
            </div>
            <div v-if="store.license">
                <p class="my-1">
                    <b>Data attivazione: </b>
                        {{ store.license_activation_date}}
                </p>
                <!-- <p class="my-1">
                    <b>Data scadenza: </b>
                        {{ store.license_expire_date}}
                </p> -->
            </div>
            <div v-else>
                <p class="my-1">
                    <b>Stato licenza: </b><span class="badge bg-danger text-uppercase">Non attiva</span>
                </p>
            </div>
        </b-card-text>
        <!-- <b-button href="javascript: void(0);" variant="primary">
            
        </b-button> -->
    </b-card>
</template>

<script>
export default {
    name: 'CompanyCard',
    data() {
        return {
            store: this.$auth.user().role === 'StoreManager' ? this.$auth.user().store : this.$auth.user().doctor.store,
        };
    }
}
</script>
