<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
// import Calendar from "@/views/pages/dashboard/calendar-widget";
import CompanyCard from './company-card.vue';
// import Stat from "@/components/widgets/stat";
import CoomingSoonFeature from "../../../components/cooming-soon-feature.vue";
export default {
  page: {
    title: "Dashboard",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    // Stat,
    CompanyCard,
    CoomingSoonFeature,
    // Calendar,
  },
  data() {
    return {
      title: "Dashboard",
      items: [
        {
          text: this.$auth.user().role === 'StoreManager' ? this.$auth.user().store.company_name : this.$auth.user().doctor.store.company_name,
        },
        {
          text: "Dashboard",
          active: true,
        },
      ],
    };
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <!-- <Stat /> -->
    <div class="row">
      <div class="col-lg-12 h-100">
        <CoomingSoonFeature feature-name="Statistiche">
          <div class="row">
            <div class="col-2">
              <img src="/assets/imgs/statistics_illustration.jpg" class="img-fluid"/>
            </div>
            <div class="col-8">
              <h4 class="text-center ">Tieni sotto controllo i progressi!</h4>
              <p class="text-center">
                Grazie a questa funzionalità potrai tenere traccia dei nuovi clienti aggiunti al tuo sistema AdaptaEasy

              </p>
            </div>
          </div>

        </CoomingSoonFeature>
      </div>
    </div>
    <!-- Rapid shortcuts -->
    <!-- <div class="row">
      <div class="col-md-4">
        <b-card bg-variant="primary" class="text-white-50">
          <h5 class="mt-0 mb-4 text-white">
            <i class="uil-user-circle me-3"></i> Clienti
          </h5>
          <b-button variant="light"> <i class="mdi mdi-plus me-2"></i>Registra cliente</b-button>
        </b-card>
      </div>
      <div class="col-md-4">
        <b-card bg-variant="primary" class="text-white-50">
          <h5 class="mt-0 mb-4 text-white">
            <i class="uil-house-user me-3"></i> Operatori
          </h5>
          <b-button variant="light"> <i class="mdi mdi-plus me-2"></i>Aggiungi operatore</b-button>
        </b-card>
      </div>
      <div class="col-md-4">
        <b-card bg-variant="primary" class="text-white-50">
          <h5 class="mt-0 mb-4 text-white">
            <i class="uil-laptop me-3"></i> Sistemi
          </h5>
          <b-button variant="light"> <i class="tachometer-fast me-2"></i>Visualizza lo stato</b-button>
        </b-card>
      </div>
    </div> -->
    <div class="row h-100">
      <div class="col-lg-4">
        <CompanyCard />
      </div>
      <div class="col-lg-8 h-100">
        <CoomingSoonFeature feature-name="Gestione degli appuntamenti">
          <div class="row">
            <div class="col-12">
              <h4 class="text-center ">Gestisci le sessioni di training direttamente a qui!</h4>
            </div>
            <div class="col-3">
              <img src="/assets/imgs/appointment_illustrations.jpg" class="img-fluid"/>
            </div>
            <div class="col-6 offset-1 align-self-center">
              <p>
                Grazie a questa funzionalità potrai organizzare e gestire le sessioni di training.
              </p>
            </div>
          </div>
        </CoomingSoonFeature>
      </div>
    </div>
    <!-- <Calendar />     -->
  </Layout>
</template>
